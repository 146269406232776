<template>
    <el-dialog
        :visible.sync="visible"
        title="登录"
        :close-on-click-modal="false"
        custom-class="login_dialog"
        width="28vw"
        top="20vh"
        @close="close"
    >
        <div class="trial_apply_content">
            <el-form :model="form" ref="form" label-width="60px">
                <el-form-item
                    prop="account"
                    label="账号"
                    :rules="[
                        { required: true, message: '请输入您的账号', trigger: 'blur' },
                        {pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/, message: `账号格式错误`, trigger: ['blur']},
                    ]"
                >
                    <el-input v-model="form.account" placeholder="请输入您的账号"></el-input>
                </el-form-item>
                <el-form-item
                    prop="code"
                    label="验证码"
                >
                    <div class="code_item">
                        <el-input v-model="form.code"></el-input>
                        <el-button @click="getCode" type="primary" :disabled="seconds > 0">{{ seconds > 0 ? `${seconds}秒后重发` : '获取验证码'}}</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <span @click="openTrialApply">无账号，去申请</span>
            <el-button @click="submit" type="primary">登 录</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { validateForm, sleep } from "@/utils/utils";

export default {
    data: function() {
        return {
            visible: false,
            form: {
                account: '',
                code: '',
            },
            seconds: 0,
        }
    },
    mounted() {
        this.$bus.$on('openLoginDialog', () => {
            this.visible = true
        })
    },
    methods: {
        close() {
            Object.assign(this.$data, this.$options.data());
        },
        openTrialApply() {
            this.$emit('openTrialApply')
            this.close()
        },
        async submit() {
            await validateForm(this.$refs.form);
            if (!this.form.code) {
                return this.$message.warning('请输入验证码')
            }
            this.API.user.login({
                code: this.form.code,
                key: this.form.account,
                loginWay: 0,
            }).then(async (res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.$message.success('登录成功！')
                    this.close()
                    this.$router.push({ path: "/formal"})
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        async getCode() {
            await validateForm(this.$refs.form);
            this.API.user.captcha({
                mobileNumber: this.form.account
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.seconds = 60;
                    this.startLoginCooling();
                } else {
                    this.$message.warning(res.message)
                }
            })
        },
        async startLoginCooling() {
            await sleep(1000);
            if (this.seconds != 0) {
                this.seconds -= 1;
                this.startLoginCooling();
            }
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
    background: rgb(32,32,32);
}
/deep/ .el-dialog__header {
    display: flex;
    justify-Content: center;
    align-items: center;
    .el-dialog__title {
        color: #fff;
        font-size: 24px;
        font-weight: bold;
    }
}
/deep/ .el-dialog__body {
  padding-top: 24px 20px;
  .el-form-item__label {
      color: #fff;
      font-size: 16px;
  }
  .el-input__inner {
      background: rgb(32,32,32);
      border-color: rgba(255, 255, 255, 0.08);
      color: #fff;
  }
  .el-form-item.is-error .el-input__inner {
      border-color: #F56C6C
  }
  .code_item {
        display: flex;
        align-items: center;
        .el-button {
            padding: 12px 24px;
            margin-left: 24px;
            background: #3664EC;
            border-color: #3664EC;
            &:hover {
                background: #3D7EFF;
                 border-color: #3D7EFF;
            }
        }
        .is-disabled {
            background-color: #a0cfff;
            border-color: #a0cfff;
            &:hover {
                background-color: #a0cfff;
                border-color: #a0cfff;
            }
        }
  }
}
.dialog-footer {
    display: flex;
    // justify-content: space-between;
    align-items: flex-end;
    > span {
        color: #FFFFFF;
        opacity: 0.5;
        text-decoration: underline;
        cursor: pointer;
        margin-right: 60px;
        &:hover {
            color: #66b1ff;
        }
    }
    .el-button {
        padding: 12px 80px;
        background: #3664EC;
        border-color: #3664EC;
        &:hover {
            background: #3D7EFF;
            border-color: #3D7EFF;
        }
    }
}

</style>
