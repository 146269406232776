<!-- 客群管理 -->
<template>
  <div class="scene">
    <section class="part1">
      <img class="banner_bg" src="@/assets/images/home/banner2.png" alt="" />
      <div class="w">
        <div class="text_box" id="textBox">
          <img src="@/assets/images/home/banner2_1.png" alt="">

          <button @click="openLogin">
            {{ is_login ? "立即开始" : "申请试用" }}
            <img src="@/assets/images/home/right_arrow.svg" alt="" />
          </button>
        </div>
      </div>
    </section>
    <section class="part2">

      <div class="block" style="background: #f1f7fb">
        <div class="w">
          <h4>虚拟直播应用场景</h4>
          <div class="apply_scene">
            <ul style="width: 577px">
              <li>
                <img src="@/assets/images/home/apply_scene1.png" alt="" />
                <div class="scene_info">
                  <div class="scene_name">新闻主播</div>
                  <div class="scene_desc">财经新闻播报，形象气质佳</div>
                </div>
              </li>
              <li>
                <img src="@/assets/images/home/apply_scene2.png" alt="" />
                <div class="scene_info">
                  <div class="scene_name">投教老师</div>
                  <div class="scene_desc">课程内容丰富，教学互动强</div>
                </div>
              </li>
            </ul>
            <ul style="width: 302px">
              <li>
                <img src="@/assets/images/home/apply_scene3.png" alt="" />
                <div class="scene_info">
                  <div class="scene_name">分析师</div>
                  <div class="scene_desc">投研分析，观点有格局</div>
                </div>
              </li>
            </ul>
            <ul style="width: 303px">
              <li>
                <img src="@/assets/images/home/apply_scene4.png" alt="" />
                <div class="scene_info">
                  <div class="scene_name">投资顾问</div>
                  <div class="scene_desc">专属投资服务，专业有深度</div>
                </div>
              </li>
              <li>
                <img src="@/assets/images/home/apply_scene5.png" alt="" />
                <div class="scene_info">
                  <div class="scene_name">智能客服</div>
                  <div class="scene_desc">智能问答，服务很亲密</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="w">
          <h4>专家解读</h4>
          <ul class="expert_analyze">
            <li style="width: 367px">
              <img src="@/assets/images/home/analyze1.png" alt="" />
            </li>
            <li class="" style="width: 407px">
              <img src="@/assets/images/home/analyze2.png" alt="" />
            </li>
            <li style="width: 367px">
              <img src="@/assets/images/home/analyze3.png" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </section>

    <TrialApply v-if="trial_visible" @closeTrialApply="closeTrialApply" />
    <Login @openTrialApply="openTrialApply" />
  </div>
</template>

<script>
import "swiper/css/swiper.min.css";
import Swiper from "swiper";
import TrialApply from "../components/TrialApply/index.vue";
import ScrollReveal from "scrollreveal";
import Login from "../components/Login/index.vue";
import { getCookie, debounce } from "@/utils/utils.js";
export default {
  data() {
    return {
      trial_visible: false,
      scrollReveal: ScrollReveal(),
      is_login: false,
      mySwiper: null,
      swipeIndex: 0,
    };
  },
  components: {
    TrialApply,
    Login,
  },
  created() {
    if (getCookie("token")) {
      this.is_login = true;
    } else {
      this.is_login = false;
    }
  },
  methods: {
    
    // 控制申请试用窗口
    openTrialApply() {
      this.trial_visible = true;
    },
    closeTrialApply() {
      this.trial_visible = false;
    },
    // 打开登录弹框
    openLogin() {
      if (!this.is_login) {
        this.$bus.$emit("openLoginDialog");
      } else {
        this.$router.push({ path: "/formal" });
      }
    },
    // handleScroll(e) {
    //   const that = this;
    //   let scrollTop =
    //     document.documentElement.scrollTop || document.body.scrollTop;
    //   let h1 = this.$refs.liveVideoBox.offsetTop;
    //   let h2 = this.$refs.liveVideoBox.clientHeight;
    //   if (scrollTop + 400 > h1 && scrollTop + 500 < h1 + h2) {
    //     if (that.swipeIndex === 0) {
    //       this.$refs.acrossVideo.play();
    //     } else {
    //       this.$refs.verticalVideo.play();
    //     }
    //   }
    // },
  },
  mounted() {
    const that = this;
    var mySwiper = new Swiper(".swiper", {
      loop: false,
      // effect: "fade",
      lazyLoading: true, //?????
      on: {
        slideChangeTransitionEnd: function () {
          // alert(this.activeIndex); //切换结束时，告诉我现在是第几个slide
          that.swipeIndex = this.activeIndex;
        },
      },
    });
    this.mySwiper = mySwiper;

    // this.$nextTick(() => {
    //   window.addEventListener("scroll", debounce(this.handleScroll, 500), true);
    // });
  },
  beforeDestroy() {},
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
