var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"visible":_vm.visible,"title":"申请试用","close-on-click-modal":false,"custom-class":"trial_apply_dialog","width":"36vw"},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.close}},[_c('div',{staticClass:"trial_apply_content"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"104px"}},[_c('el-form-item',{attrs:{"prop":"name","label":"申请人姓名","rules":[{ required: true, message: '请输入申请人姓名', trigger: 'blur' }]}},[_c('el-input',{attrs:{"placeholder":"请输入申请人姓名"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"prop":"phone","label":"手机号码","rules":[
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    {pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/, message: `手机号格式错误`, trigger: ['blur']}
                ]}},[_c('el-input',{attrs:{"placeholder":"请输入手机号码"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('el-form-item',{attrs:{"prop":"company","label":"公司名称","rules":[{ required: true, message: '请输入公司名称', trigger: 'blur' }]}},[_c('el-input',{attrs:{"placeholder":"请输入公司名称"},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})],1),_c('el-form-item',{attrs:{"prop":"email","label":"企业邮箱","rules":[
                    { required: true, message: '请输入企业邮箱', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']},
                ]}},[_c('el-input',{attrs:{"placeholder":"请输入企业邮箱"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('el-form-item',{attrs:{"prop":"useCase","label":"使用场景"}},[_c('el-input',{attrs:{"placeholder":"请输入使用场景"},model:{value:(_vm.form.useCase),callback:function ($$v) {_vm.$set(_vm.form, "useCase", $$v)},expression:"form.useCase"}})],1),_c('el-form-item',{attrs:{"prop":"obtain","label":"获取渠道"}},[_c('el-input',{attrs:{"placeholder":"请输入获取渠道"},model:{value:(_vm.form.obtain),callback:function ($$v) {_vm.$set(_vm.form, "obtain", $$v)},expression:"form.obtain"}})],1),_c('span',{staticClass:"des"},[_vm._v("提交后，相关人员1-2工作日与您联系")])],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('span',{on:{"click":_vm.openLogin}},[_vm._v("有账号，去登录")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("提 交")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }