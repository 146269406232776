<template>
    <el-dialog
        :visible.sync="visible"
        title="申请试用"
        :close-on-click-modal="false"
        custom-class="trial_apply_dialog"
        width="36vw"
        @close="close"
    >
        <div class="trial_apply_content">
            <el-form :model="form" ref="form" label-width="104px">
                <el-form-item
                    prop="name"
                    label="申请人姓名"
                    :rules="[{ required: true, message: '请输入申请人姓名', trigger: 'blur' }]"
                >
                    <el-input v-model="form.name" placeholder="请输入申请人姓名"></el-input>
                </el-form-item>
                <!-- <el-form-item
                    prop="job"
                    label="申请人职位"
                    :rules="[{ required: true, message: '请输入申请人职位', trigger: 'blur' }]"
                >
                    <el-input v-model="form.job" placeholder="请输入申请人职位"></el-input>
                </el-form-item> -->
                <el-form-item
                    prop="phone"
                    label="手机号码"
                    :rules="[
                        { required: true, message: '请输入手机号码', trigger: 'blur' },
                        {pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/, message: `手机号格式错误`, trigger: ['blur']}
                    ]"
                >
                    <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
                </el-form-item>
                <el-form-item
                    prop="company"
                    label="公司名称"
                    :rules="[{ required: true, message: '请输入公司名称', trigger: 'blur' }]"
                >
                    <el-input v-model="form.company" placeholder="请输入公司名称"></el-input>
                </el-form-item>
                <el-form-item
                    prop="email"
                    label="企业邮箱"
                    :rules="[
                        { required: true, message: '请输入企业邮箱', trigger: 'blur' },
                        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']},
                    ]"
                >
                    <el-input v-model="form.email" placeholder="请输入企业邮箱"></el-input>
                </el-form-item>
                <el-form-item
                    prop="useCase"
                    label="使用场景"
                >
                    <el-input v-model="form.useCase" placeholder="请输入使用场景"></el-input>
                </el-form-item>
                <el-form-item
                    prop="obtain"
                    label="获取渠道"
                >
                    <el-input v-model="form.obtain" placeholder="请输入获取渠道"></el-input>
                </el-form-item>
                <span class="des">提交后，相关人员1-2工作日与您联系</span>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <span @click="openLogin">有账号，去登录</span>
            <el-button @click="submit" type="primary">提 交</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { validateForm } from "@/utils/utils";

export default {
    data: function() {
        return {
            visible: true,
            form: {
                name: '',
                // job: '',
                phone: '',
                company: '',
                email: '',
                useCase: '', // 使用场景
                obtain: '', // 获取渠道
            },
        }
    },
    methods: {
        close() {
            this.$emit('closeTrialApply')
        },
        async submit() {
            await validateForm(this.$refs.form);
            this.API.user.apply({
                userName: this.form.name,
                mobileNumber: this.form.phone,
                companyName: this.form.company,
                email: this.form.email,
                sourceInfo: this.form.obtain,
                remark: this.form.useCase
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.$message.success('申请成功!')
                    this.close()
                } else {
                    this.$message.error(res.message || '申请失败!')
                }
            })
        },
        openLogin() {
            this.$emit('closeTrialApply')
            this.$bus.$emit('openLoginDialog')
        }
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
    background: rgb(32,32,32);
}
/deep/ .el-dialog__header {
    display: flex;
    justify-Content: center;
    align-items: center;
    .el-dialog__title {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
    }
}
/deep/ .el-dialog__body {
  padding-top: 24px 20px;
  .el-form-item__label {
      color: #fff;
      font-size: 16px;
  }
  .el-input__inner {
      background: rgb(32,32,32);
      border-color: rgba(255, 255, 255, 0.08);
      color: #fff;
  }
  .el-form-item.is-error .el-input__inner {
      border-color: #F56C6C
  }
  .des {
    display: inline-block;
    width: 100%;
    text-align: right;
    color: #FFFFFF;
    opacity: 0.5;
  }
}
.dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    > span {
        color: #FFFFFF;
        opacity: 0.5;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
            color: #66b1ff;
        }
    }
    .el-button {
        padding: 12px 46px;
        background: #3664EC;
        border-color: #3664EC;
        &:hover {
            background: #3D7EFF;
        }
    }
}
</style>